import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-fr';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';

const lang = $('body').attr('data-lang');

const commonParams = {
  regional: lang,
  showOn: 'both',
  buttonText: '',
  buttonImage: 'theme/styles/assets/icons/calendar-today.png',
  buttonImageOnly: false,
  defaultDate: '+1w',
  numberOfMonths: 1,
  changeYear: true,
};

$('.datepicker-child').datepicker({
  ...commonParams,
  defaultDate: '-10y',
  minDate: '-20y',
  maxDate: '0',
});

$('.datepicker-adult').datepicker({
  ...commonParams,
  defaultDate: '-20y',
  minDate: '-90y',
  maxDate: '0',
});
