const navigationMobile = () => {
  $('#button--mobile').on('click', () => {
    $('body').toggleClass('menu-open');
    $('#navigation-main').toggle();
  });
};

const navigationSize = () => {
  const position = $(window).scrollTop();
  const limit = $('.header-top').innerHeight();
  const headerTopHeight = $('#header').innerHeight();

  const computePadding = () => {
    if ($('body').hasClass('header-small')) {
      $('body').css('paddingTop', headerTopHeight);
    } else {
      $('body').css('paddingTop', 0);
    }
  };

  const handlePosition = (isFixed) => {
    if (isFixed) {
      $('body').addClass('header-small');
      $('#header').css('top', -limit);
    } else {
      $('body').removeClass('header-small');
      $('#header').css('top', 0);
    }
    computePadding();
  };

  handlePosition(position > limit);

  $(window).on('scroll', (e) => {
    handlePosition($(e.currentTarget).scrollTop() > limit);
  });
};

const navigationDropdown = () => {
  const viewport = $(window).width();
  if (viewport < 768) {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      $(e.currentTarget).next().toggle();
      return false;
    });
  } else {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      const navSub = $(e.currentTarget).parent();
      const isFocus = navSub.hasClass('focus');
      $('#navigation-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      e.stopPropagation();
      return false;
    });
    $('html').on('click', () => {
      $('#navigation-main li[data-navsub]').removeClass('focus');
    });
  }
};

const scrollTop = () => {
  const domElm = $('#button--scroll-top');
  domElm.fadeOut(0);
  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > 220) {
      domElm.fadeIn(300);
    } else {
      domElm.fadeOut(300);
    }
  });
  domElm.on('click', () => {
    $('html, body').animate({
      scrollTop: 0,
    }, '500');
    return false;
  });
};

navigationMobile();
navigationDropdown();
navigationSize();
scrollTop();
