import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

$('#slick-testimonial').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: true,
  fade: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  centerMode: false,
  autoplaySpeed: 6000,
  appendArrows: '#slick-testimonial-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-left"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-right"></span></button>',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        autoplay: false,
      },
    },
  ],
});

$('#slick-logos').slick({
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  variableWidth: false,
  arrows: false,
  autoplaySpeed: 1,
  speed: 3000,
  cssEase: 'linear',
  responsive: [{
    breakpoint: 1200,
    settings: {
      slidesToShow: 5,
    },
  },
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 576,
    settings: {
      slidesToShow: 2,
    },
  },
  ],
});

$('#slick-home').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: true,
  arrows: false,
  autoplay: true,
  pauseOnHover: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [{
    breakpoint: 1200,
    settings: {},
  },
  {
    breakpoint: 991,
    settings: {},
  },
  {
    breakpoint: 576,
    settings: {},
  },
  ],
});

// $('#slick').slick({
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   dots: true,
//   fade: false,
//   infinite: true,
//   arrows: true,
//   autoplay: true,
//   adaptiveHeight: true,
//   cssEase: 'ease-in-out',
//   speed: 400,
//   centerMode: false,
//   autoplaySpeed: 6000,
//   appendArrows: '#slick-arrows',
//   prevArrow: '<button class="arrow-prev"><span class="sprite arrow-large-prev"></span></button>',
//   nextArrow: '<button class="arrow-next"><span class="sprite arrow-large-next"></span></button>',
//   responsive: [
//     {
//       breakpoint: 1200,
//       settings: {},
//     },
//     {
//       breakpoint: 991,
//       settings: {
//         slidesToShow: 2,
//       },
//     },
//     {
//       breakpoint: 768,
//       settings: {
//         slidesToShow: 1,
//         autoplay: false,
//       },
//     },
//   ],
// });

setTimeout(() => {
  $('#slick-home .slick-active').addClass('animation');
}, 250);

$('#slick-home').on('afterChange', () => {
  $('#slick-home .slick-active').addClass('animation');
});

$('#slick-home').on('beforeChange', () => {
  $('#slick-home .slick-active').removeClass('animation');
});
