const toggleFields = () => {
  $('#wedding-contract-toggle').hide();
  $('#caf-number-toggle').hide();

  // Family status, if married contract field required
  $('#family').on('change', (e) => {
    const { currentTarget } = e;
    if (currentTarget.value === 'Marié') {
      $('#wedding-contract-toggle').show();
      $('#wedding-contract').attr('required', 'required');
    } else {
      $('#wedding-contract-toggle').hide();
      $('#wedding-contract').removeAttr('required');
    }
  });

  // CAF status, if yes number field required
  $('#caf').on('change', (e) => {
    const { currentTarget } = e;
    if (currentTarget.value === 'Oui') {
      $('#caf-number-toggle').show();
      $('#caf-number').attr('required', 'required');
    } else {
      $('#caf-number-toggle').hide();
      $('#caf-number').removeAttr('required');
    }
  });

  // Adding child
  let childCount = 1;
  const childLimit = 5;
  for (let i = 2; i <= 100; i += 1) {
    $(`#child-form-${i}`).hide();
  }
  $('#add-child').on('click', () => {
    childCount += 1;
    $(`#child-form-${childCount}`).show();
    $(`#child-form-${childCount} input, #child-form-${childCount} select`).attr('required', 'required');
    if (childCount === childLimit) {
      $('#add-child').hide();
    }
  });
};

toggleFields();
