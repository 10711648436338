import 'sticky-kit/dist/sticky-kit';

const stickykitInit = () => {
  const viewport = $(window).width();
  const reservationHeader = $('#navigation-main').innerHeight();

  if (viewport > 576) {
    $('#sticky-wp').stick_in_parent({
      offset_top: reservationHeader,
    });
  } else {
    $('#sticky-wp').trigger('sticky_kit:detach');
  }
};

stickykitInit();

$(window).on('resize', () => {
  stickykitInit();
});
